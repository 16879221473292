function recaptchaCallback() {
	$('#f_recaptcha').valid();
}

function cookiePopup() {
    Cookies.set('popup-formation', 'close', {expires : 1});
}

$(document).ready(function () {
	// FIX LISTING FAQ UNDERLINE ON TITLE
	$('.listingFAQ').prev().addClass('titleFAQ');

	$('.toggleMenu').click(function () {
		$('body').toggleClass('menuOpen');
		$('.mainMenu').toggleClass('active');
		$(this).toggleClass('active');
		$('.mainMenu-subMenu').removeClass('active');
	});

	$('.mainMenu-link').click(function () {
		$(this).parent().find('.mainMenu-subMenu').addClass('active');
	});

	$('.mainMenu-subMenu').find('.backBtn').click(function () {
		$(this).parent().removeClass('active');
	});

	// OWL-carousel full
	var IDnumber = 1;
	var NEXTPREVnumber = 1;
	$('.owl-carousel-full').each(function () {
		var newID = 'owl-carousel-full' + IDnumber;
		$(this).attr('id', newID);
		IDnumber++;
		var owl = $('#' + newID);
		owl.owlCarousel({
			loop: true,
			nav: false,
			items: 1,
			dots: false,
		});
		owl.trigger('refresh.owl.carousel');
		$(this).next().find('.owlNextBloc').addClass('owlNextBloc' + NEXTPREVnumber)
		$(this).next().find('.owlPrevBloc').addClass('owlPrevBloc' + NEXTPREVnumber)
		$('.owlNextBloc' + NEXTPREVnumber).click(function () {
			owl.trigger('next.owl.carousel');
		});
		$('.owlPrevBloc' + NEXTPREVnumber).click(function () {
			owl.trigger('prev.owl.carousel', [300]);
		});
		$('.owlNextBlocMobile').click(function () {
			owl.trigger('next.owl.carousel');
		});
		$('.owlPrevBlocMobile').click(function () {
			owl.trigger('prev.owl.carousel');
		});
		NEXTPREVnumber++;
	});

	$('.owlNextBlocFull').click(function () {
		var title;
		var cpt = -1;
		$('.titleSliderNav').each(function() {
			if($(this).hasClass('active')) {
				title = $(this);
			}
			cpt++;
		});
		title.removeClass('active');
		if (title.attr('data-title') == cpt) {
			$('.titleSliderNav:first-child').addClass('active');
		} else {
			title.next().addClass('active');
		}
	});

	$('.owlPrevBlocFull').click(function () {
		var title;
		$('.titleSliderNav').each(function() {
			if($(this).hasClass('active')) {
				title = $(this);
			}
		});
		title.removeClass('active');
		if (title.attr('data-title') == 0) {
			$('.titleSliderNav:last-child').addClass('active');
		} else {
			title.prev().addClass('active');
		}
	});

	$('.titleSliderNav').click(function(){
		var nbSlide = $(this).attr('data-title');
		$('.titleSliderNav').removeClass('active');
		$(this).addClass('active');
		$('.owl-carousel-full').trigger('to.owl.carousel', [nbSlide, 300]);
	});
	$('.owl-carousel-full').trigger('refresh.owl.carousel');

	// OWL-carousel miniature
	var IDnumber = 1;
	var NEXTPREVnumber = 1;
	$('.owl-carousel-miniature').each(function () {
		var newID = 'owl-carousel-miniature' + IDnumber;
		$(this).attr('id', newID);
		IDnumber++;
		var owl = $('#' + newID);
		owl.owlCarousel({
			loop: true,
			nav: false,
			margin: 10,
			items: 4,
			dots: false,
			responsiveClass: true,
			responsive: {
				0: {
					items: 1,
					dots: true,
				},
				600: {
					items: 2,
				},
				1000: {
					items: 2,
				}
			}
		});

		owl.trigger('refresh.owl.carousel');
		$(this).next().addClass('owlNextBloc' + NEXTPREVnumber)
		$(this).next().next().addClass('owlPrevBloc' + NEXTPREVnumber)
		$('.owlNextBloc' + NEXTPREVnumber).click(function () {
			owl.trigger('next.owl.carousel');
		})
		$('.owlPrevBloc' + NEXTPREVnumber).click(function () {
			owl.trigger('prev.owl.carousel', [300]);
		});
		NEXTPREVnumber++;
	});

	// OWL-carousel pas miniature
	var IDnumber = 1;
	var NEXTPREVnumber = 1;
	$('.owl-carousel-pas-miniature').each(function () {
		var newID = 'owl-carousel-pas-miniature' + IDnumber;
		$(this).attr('id', newID);
		IDnumber++;
		var owl = $('#' + newID);
		owl.owlCarousel({
			loop: true,
			nav: false,
			margin: 0,
			items: 1,
			dots: false
		});

		owl.trigger('refresh.owl.carousel');
		$(this).next().addClass('owlNextBloc' + NEXTPREVnumber)
		$(this).next().next().addClass('owlPrevBloc' + NEXTPREVnumber)
		$('.owlNextBloc' + NEXTPREVnumber).click(function () {
			owl.trigger('next.owl.carousel');
		});
		$('.owlPrevBloc' + NEXTPREVnumber).click(function () {
			owl.trigger('prev.owl.carousel', [300]);
		});
		NEXTPREVnumber++;
	});

	// OWL-CAROUSEL TEMOIGNAGES
	$('.owl-testimony').owlCarousel({
		loop: true,
		nav: false,
		items: 4,
		dots: false,
		margin: 20,
		responsiveClass: true,
		responsive: {
			0 : {
				items: 1,
				dots: true,
			},
			575 : {
				items: 2,
				dots: false,
			},
			991 : {
				items: 3,
				dots: false,
			}
		}
	});

	$('.owl-actusHomepage').owlCarousel({
		loop: true,
		nav: false,
		items: 4,
		dots: false,
		margin: 20,
		responsiveClass: true,
		responsive: {
			0 : {
				items: 1,
				dots: true,
			},
			575 : {
				items: 2,
				dots: false,
			},
			991 : {
				items: 3,
				dots: false,
			}
		}
	});

	// SLIDER PARTENAIRE HOMEPAGE
	$('.owl-blockWall').owlCarousel({
		loop: true,
		nav: false,
		items: 5,
		dots: false,
		margin: 20,
		responsiveClass: true,
		responsive: {
			0 : {
				items: 2,
				dots: true,
			},
			575 : {
				items: 3,
				dots: false,
			},
			991 : {
				items: 5,
				dots: false,
			}
		}
	});

	$('.owlNextWall').click(function () {
		$('.owl-blockWall').trigger('next.owl.carousel');
	});
	$('.owlPrevWall').click(function () {
		$('.owl-blockWall').trigger('prev.owl.carousel');
	});

	$('.owl-blockWall-single').owlCarousel({
		nav: false,
		items: 5,
		dots: false,
		margin: 20,
		responsiveClass: true,
		responsive: {
			0 : {
				items: 2,
				mouseDrag: true,
				pullDrag: true,
				touchDrag: true,
				loop: true,
				dots: true,
			},
			575 : {
				items: 3,
				mouseDrag: true,
				pullDrag: true,
				touchDrag: true,
				loop: true,
				dots: false,
			},
			991 : {
				items: 5,
				mouseDrag: false,
				pullDrag: false,
				touchDrag: false,
				loop: false,
				dots: false,
			}
		}
	});

	// Slide school.php
	$('.owl-twoCol').owlCarousel({
		loop: true,
		nav: false,
		items: 1,
		dots: false,
		margin: 15,
		responsiveClass: true,
		responsive: {
			0 : {
				dots: true,
			},
			575 : {
				dots: false,
			},
			991 : {
				dots: false,
			}
		}
	});

	$('.owl-twoColDestroyed').owlCarousel({
		loop: false,
		nav: false,
		items: 1,
		dots: false,
		freeDrag: false,
		mouseDrag: false,
		touchDrag: false
	});

	$('.owlTwoColNext').click(function () {
		$('.owl-twoCol').trigger('next.owl.carousel');
	});
	$('.owlTwoColPrev').click(function () {
		$('.owl-twoCol').trigger('prev.owl.carousel');
	});

	// OWL-carousel PARTNERS
	var IDnumber = 1;
	var NEXTPREVnumber = 1;
	$('.owl-carousel-partners').each(function () {
		var newID = 'owl-carousel-partners' + IDnumber;
		$(this).attr('id', newID);
		IDnumber++;
		var owl = $('#' + newID);
		owl.owlCarousel({
			loop: false,
			nav: false,
			items: 7,
			dots: false,
			autoplay: true,
			autoplayTimeout: 4000,
			autoplayHoverPause: true,
			responsiveClass: true,
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 5,
				},
				1000: {
					items: 7,
				}
			}
		});

		owl.trigger('refresh.owl.carousel');
		$(this).next().addClass('owlNextBloc' + NEXTPREVnumber)
		$(this).next().next().addClass('owlPrevBloc' + NEXTPREVnumber)
		$('.owlNextBloc' + NEXTPREVnumber).click(function () {
			owl.trigger('next.owl.carousel');
		})
		$('.owlPrevBloc' + NEXTPREVnumber).click(function () {
			owl.trigger('prev.owl.carousel', [300]);
		});
		NEXTPREVnumber++;
	});

	// OWL-carousel full
	var IDnumber = 1;
	var NEXTPREVnumber = 1;
	$('.owl-carousel-testimony').each(function () {
		var newID = 'owl-carousel-testimony' + IDnumber;
		$(this).attr('id', newID);
		IDnumber++;
		var owl = $('#' + newID);
		owl.owlCarousel({
			loop: true,
			nav: false,
			items: 1,
			dots: false
		});
		owl.trigger('refresh.owl.carousel');
		$(this).next().addClass('owlNextBloctest' + NEXTPREVnumber)
		$(this).next().next().addClass('owlPrevBloctest' + NEXTPREVnumber)
		$('.owlNextBloctest' + NEXTPREVnumber).click(function () {
			owl.trigger('next.owl.carousel');
		})
		$('.owlPrevBloctest' + NEXTPREVnumber).click(function () {
			owl.trigger('prev.owl.carousel', [300]);
		});
		NEXTPREVnumber++;
	});

	// BLOCK FORMATION
	var eqLvl1;
	var eqLvl2;
	$('.undermenu1').click(function () {
		eqLvl1 = $(this).index();
		$('.undermenu1').removeClass('active');
		$('.menu2').removeClass('active');
		$(this).addClass('active');
		$('.menu2').eq(eqLvl1).addClass('active');
		$('.menu2container').addClass('active');
		$('.menu3container').removeClass('active');
		$('.menu3').removeClass('active');
		$('.undermenu2').removeClass('active');
	});

	$('.undermenu2').click(function () {
		if (eqLvl1 == 0) {
			eqLvl2 = $(this).index();
		} else if (eqLvl1 == 1) {
			eqLvl2 = $(this).index() + 4;
		}
		$('.undermenu2').removeClass('active');
		$('.menu3').removeClass('active');
		$(this).addClass('active');
		$('.menu3').eq(eqLvl2).addClass('active');
		$('.menu3container').addClass('active');
	});

	$('.undermenu3').click(function () {
		$('.undermenu3').removeClass('active');
		$(this).addClass('active');
	});

	// Single formation programme en detail
	$('.itemProg').click(function () {
		$(this).toggleClass('active');
		if ($(this).hasClass('active')) {
			var heightChild = $(this).find('.block-text').children().outerHeight(true);
			$(this).find('.block-text').css('height', heightChild);
		} else {
			$(this).find('.block-text').css('height', '0');
		}
	});

	/* VIDEO BG */
	var timeoutId;
	function videobgEnlarge() {
		var videoBgAspect = $(".videobg-aspect");
		var videoBgWidth = $(".videobg-width");
		var videoAspect = videoBgAspect.outerHeight() / videoBgAspect.outerWidth();
		var windowAspect = ($(window).height() / $(window).width());
		if (windowAspect > videoAspect) {
			videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
		} else {
			videoBgWidth.width(100 + "%")
		}
	}

	$(window).resize(function () {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(videobgEnlarge, 100);
	});

	$(function () {
		videobgEnlarge();
	});

	$.validator.addMethod("validateRecaptcha", function (value, element) {
		if (grecaptcha.getResponse() == '') {
			return false;
		} else {
			return true;
		}
	}, "Vous devez valider le reCAPTCHA");

	setTimeout(function () {
		if ($('#container_form form').length) {
			$('#contactRgpd').rules('add', {
				validateCgv: true
			});
			$('#f_recaptcha').rules('add', {
				validateRecaptcha: true
			});
		}
	}, 100);

	$.extend($.validator.messages, {
		required: "Ce champ est obligatoire.",
		email: "Veuillez fournir une adresse électronique valide."
	});
	$.validator.addMethod("validateCgv", function (value, element) {
		return $('#contactRgpd').is(':checked');
	}, "Vous devez avoir pris connaissance de notre politique de confidentialité");

	$('#container_form form').validate({
		errorElement: "span",
		errorPlacement: function (error, element) {
			error.addClass("help-block");
			if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
				var parentElm = element.closest('.form-group');
				error.addClass("checkbox");
				parentElm.append(error);
			} else if (element.attr('type') === undefined) {
				var parentElm = element.closest('.form-group');
				error.addClass("select");
				parentElm.append(error);
			}
			else
				error.insertAfter(element);
		},
		highlight: function (element, errorClass, validClass) {
			$(element).parents(".form-group").addClass("has-error").removeClass("has-success");
		},
		unhighlight: function (element, errorClass, validClass) {
			$(element).parents(".form-group").addClass("has-success").removeClass("has-error");
		},
		submitHandler: function (form) {
			form.submit();
		}
	});

	$('.mainMenu-linkContainer').last().addClass('ctaMobile');
	$('.mainMenu-linkContainer2').first().addClass('first');

	$('.undermenu2').on('click', function () {
		if ($(window).width() < 767) {
			var page = $(this).data('href');
			var Scrollto = $(page).offset().top - 100;
			var speed = 750;
			$('html, body').animate({ scrollTop: Scrollto }, speed);
			return false;
		}
	});

	var validCounter = 0;
	function counter() {
		if ($('.feature24').length != '0') {
			var featureTop = $('.feature24').offset().top;
			var viewportTop = $(window).scrollTop();
			var viewportBottom = viewportTop + $(window).height();
			if (featureTop < viewportBottom) {
				if (validCounter == 0) {
					$('.count').each(function () {
						$(this).prop('Counter', 0).animate({
							Counter: $(this).text()
						}, {
							duration: 3000,
							easing: 'swing',
							step: function (now) {
								$(this).text(Math.ceil(now));
							}
						});
					});
					validCounter = 1;
				}
			}
		}
	}
	counter();
	$(window).resize(counter);
	$(window).scroll(counter);

	// CTA dans wysiwyg
	$('a.cta').wrap('<div class="cta"></div>');

	$('.domainFc').on('change', function() {
		var value = $(this).val();
		if (value == "") {
			$('.domainChildFc').removeClass('show');
			$('.domainChildFc').addClass('hide');
			$('.domainChildFc').prop('disabled', true);
		} else {
			$('.domainChildFc').removeClass('show');
			$('.domainChildFc').addClass('hide');
			$('.domainChildFc').prop('disabled', true);
			$(".domainChildFc[data-tax='"+value+"']").addClass('show');
			$(".domainChildFc[data-tax='"+value+"']").prop('disabled', false);
		}
	});

	$('.closePopup').click(function() {
		cookiePopup();
		$('.popFormation').css('display', 'none');
	});

	$('.linkPopupImg').click(function() {
		cookiePopup();
		$('.popFormation').css('display', 'none');
	})

	if (Cookies.get('popup-formation') == 'close') {
        $('.popFormation').css('display','none');
    }

	$("#formPopup").submit(function(e) {
		e.preventDefault();
		dataForm = $(this).serialize();
		form = $(this);
		$.ajax({
            type: "POST",
			url: "/",
            data: dataForm,
            success: function (response) {
				if (response.status && response.status == 'success') {
					$('#container_popup').before('<div id="success_form_msg">'+response.message+'</div>');
					cookiePopup();
					$("#formPopup").remove();
					form.submit();
				} else {
					if (response.status == 'error') {
						$('#formPopup').after('<div id="error_form_msg">'+response.message+'</div>')
					} else {
						alert('Erreur !');
					}
				}
            }
        });
	});

	$blogTags = $('.blogTags');
	if ($blogTags.length) {
		tags = [];

		var urlParams = new URLSearchParams(window.location.search);
		tagsParams = urlParams.getAll('tags[]');
		tagsParams.forEach(tag => {
			var checkbox = $blogTags.find(`[value="${tag}"]`)
			checkbox.prop('checked', true);
			checkbox.parent().addClass('active');
		});

		$blogTags.find('[type="checkbox"]').each(function() {
			$this = $(this);
			$this.on('change', function() {
				var $checked = $blogTags.find('[type="checkbox"]:checked');
				$checked.each(function() {
					$this = $(this);
					tags.push($this.val());
				})
				
				var queryString = tags ? tags.map(tag => `tags[]=${encodeURIComponent(tag)}`).join('&') : '';
				var url = `${window.location.pathname}?${queryString}`;
				window.location.href = url;
			})
		});
	}

});
